import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findTicketByCode,
  statsTickets,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import Ticket from "../Tickets/Ticket";
import { InterventionType } from "../../_App/Types/Entites/InterventionType";
import FicheInterventionModal from "../Interventions/FicheInterventionModal";

const TableTickets = ({ setMergedData }: any) => {
  const Tickets = useAppSelector(statsTickets);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 17; // Number of tickets per page

  // Calculate total pages
  const totalPages = Math.ceil(Tickets.length / ticketsPerPage);

  // Get the current tickets for the current page
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = Tickets.slice(indexOfFirstTicket, indexOfLastTicket);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const [ShowModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();
  const [ticket, setTicket] = useState(undefined);

  const [showIntervention, setShowIntervention] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState<
    InterventionType | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetch = async () => {
      if (selectedTicket) {
        const res = await dispatch(findTicketByCode(selectedTicket));
        if (res.payload) {
          setTicket(res.payload);
        }
      }
    };
    fetch();
  }, [selectedTicket, dispatch]);
  return (
    <>
      <Ticket
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        selectedTicket={ticket}
        setSelectedTicket={setSelectedTicket}
        setSelectedIntervention={setSelectedIntervention}
        setShowIntervention={setShowIntervention}
      />
      <FicheInterventionModal
        ShowModal={showIntervention}
        setShowModal={setShowIntervention}
        intervention={selectedIntervention}
        setIntervention={setSelectedIntervention}
      />
      <table id="style-3" className="table style-3 table-hover">
        <thead>
          <tr>
            <th>Ticket</th>
            <th>Machine</th>
            <th>ODP</th>
            <th>Interventions</th>
            <th>Produits</th>
            <th>Techniciens</th>
          </tr>
        </thead>
        <tbody>
          {currentTickets.length > 0 ? (
            currentTickets.map((ticket: any, index: any) => (
              <tr key={index}>
                <td
                  onClick={() => {
                    setSelectedTicket(ticket.code);
                    setShowModal(true);
                  }}
                >
                  <a className="link" style={{ cursor: "pointer" }}>
                    {ticket.code}
                  </a>{" "}
                </td>
                <td>{ticket.machine_code}</td>
                <td>{ticket.odf_code}</td>
                <td>{ticket.intervention_count}</td>
                <td>{ticket.produits}</td>
                <td>
                  {ticket?.user_names
                    ?.split(",")
                    .map((name: any, index: any) => (
                      <span key={index}>
                        {name}
                        <br />
                      </span>
                    ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="btn"
        >
          Préc.
        </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={currentPage === i + 1 ? "btn active" : "btn"}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="btn"
        >
          Suiv.
        </button>
      </div>
    </>
  );
};

export default TableTickets;
