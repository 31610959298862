import { useEffect, useState } from "react";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { findMachinesPlanningEvents } from "../../_App/Redux/Slices/plannings/planningsSlice";
import Navbar from "../../_Layouts/Navbar/Navbar";
import EventModal from "../../Components/events/EventModal";
import Calendar from "../../Components/Plannings/Calendar";
import { findMachinesApi } from "../../_App/Redux/Slices/machines/machineSlice";
import YearlyCalendar from "../../Components/Plannings/Calendar";
import FileInput from "../../Components/Plannings/FileInput";
import { MachinePlanningType } from "../../_App/Types/Entites/PlanningType";

const PlanningCalendar = () => {
  const dispatch = useAppDispatch();
  const [events, setEvents] = useState<any>([]);
  const [ShowModal, setShowModal] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [date, setDate] = useState(undefined);

  const [rand, setRand] = useState(0);
  const increaseRand = () => {
    setRand((prevRand) => prevRand + 1);
  };
  const [reload, setReload] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      console.log("hi");
      try {
        const machinesPlaning = await dispatch(
          findMachinesPlanningEvents(null)
        );
        console.log(events, machinesPlaning.payload);
        const machineEvents = machinesPlaning.payload.map(
          (event: MachinePlanningType) => ({
            id: event.id,
            title: `${event.machine_code}  ${
              event.User ? " - " + event.User.nom : ""
            }`,
            month: event.mois - 1, // Assuming month in database is 1-based
            year: event.annee,
            color: "#e2a03f",
            data: event,
          })
        );

        setEvents(machineEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [dispatch, rand, reload]);


  return (
    <>
      <Navbar titre={"Planning"} />
      {/* <button onClick={() => console.log(events)}>test</button> */}

      <EventModal
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        event={event}
        setEvent={setEvent}
        increaseRand={increaseRand}
        date={date}
        setDate={setDate}
      />
      <FileInput type="Preventif" reload={reload} setReload={setReload} />

      <YearlyCalendar
        events={events}
        // ShowModal={ShowModal}
        // setShowModal={setShowModal}
        // setEvent={setEvent}
        // setDate={setDate}
      />
    </>
  );
};

export default PlanningCalendar;
