import React, { useState } from "react";
import ProgramsProd from "./TodayProgMachines/ODPs";
import { useAppDispatch } from "../../_App/Redux/hooks";
import LandingMachines from "../Machines/LandingMachines";
import LandingTickets from "./Tickets";

const Dashboard: React.FC = () => {
  const [ticketsFilter, setTicketsFilter] = useState<string>("");

  return (
    <div>
      <div className="row">
        <div className="col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-heading">
              <h6>Vos machines</h6>
            </div>
            <LandingMachines setTicketsFilter={setTicketsFilter} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-heading">
              <h6>Tickets ouverts</h6>
            </div>
            <LandingTickets filter={ticketsFilter} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 layout-spacing">
          <div className="widget widget-one">
            <div
              className="widget-heading"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h6 className="mb-0">Programme de production d'aujourd'hui</h6>
              <button
                className="btn btn-primary mb-2 mr-2"
                onClick={() => (window.location.href = "/calendar")}
              >
                <i className="far fa-calendar-alt mr-2"></i>Planning
              </button>
            </div>
            <ProgramsProd />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
