import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListMachines,
  findMachinesApi,
} from "../../_App/Redux/Slices/machines/machineSlice";
import Loader from "../../_Layouts/Loader/Loader";
import { ChangeMachineStatus } from "./ChangeMachineStatusModal";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import { CurrentTicket } from "../../_App/Redux/Slices/tickets/ticketSlice";
import _ from "lodash";

interface LandingMachinesProps {
  setTicketsFilter: React.Dispatch<React.SetStateAction<string>>;
}

const LandingMachines: React.FC<LandingMachinesProps> = ({
  setTicketsFilter,
}) => {
  const dispatch = useAppDispatch();
  const machines = useAppSelector(ListMachines);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [machine, setMachine] = useState<MachineType | undefined>(undefined);
  const tickets = useAppSelector(CurrentTicket);

  // New state to hold grouped tickets by machine code
  const [ticketGroups, setTicketGroups] = useState<Record<string, number>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Dispatch API calls to get machines and tickets
        await dispatch(findMachinesApi(null));
        setLoading(false);

        // Group tickets by machine_code and count occurrences
        const groups = _.mapValues(
          _.groupBy(tickets, "machine_code"),
          (group: any) => group.length
        );
        setTicketGroups(groups);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, tickets]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "En arrêt":
        return "#555555";
      case "En panne":
        return "#e7515a";
      case "En réparation":
        return "#e2a03f";
      case "En attente":
        return "#5c80ef";
      default:
        return "#1abc9c";
    }
  };

  return (
    <div
      className="searchable-container m-0"
      style={{ maxWidth: "fit-content" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div
          className="layout-top-spacing"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <ChangeMachineStatus
            ShowModal={showModal}
            setShowModal={setShowModal}
            selectedMachine={machine}
            setSelectedMachine={setMachine}
          />
          {machines?.map((item, index) =>
            item?.checklist === 1 ? (
              <div key={index} style={{ cursor: "pointer" }}>
                {/* Conditionally render the badge if there are tickets for this machine */}
                {ticketGroups[item.code] && (
                  <span
                    className="badge badge-warning"
                    onClick={() => setTicketsFilter(item.code)}
                    style={{ position: "absolute" }}
                  >
                    Tickets: {ticketGroups[item.code]}
                  </span>
                )}
                <div
                  className="soft-icons"
                  style={{ backgroundColor: getStatusColor(item.status || "") }}
                  onClick={() =>
                    (window.location.href = "/machineHistorique/" + item.code)
                  }
                >
                  <p>{item.numero_serie}</p>
                </div>
                <div
                  className="machine-etiquette"
                  onClick={() => {
                    setMachine(item);
                    setShowModal(true);
                  }}
                >
                  {item.status}
                </div>
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default LandingMachines;
