import React, { useEffect, useState } from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import TableMachines from "../../Components/Analyses/TableMachines";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findMachineDowntime,
  findMachinesStats,
  findNumberPanne,
  numberPanneMachines,
} from "../../_App/Redux/Slices/machines/machineSlice";
import PieChartComponent from "../../Components/Analyses/MachinesPie";
import {
  findTicketsStats,
  statsByPanne,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import moment from "moment";
import TableTickets from "../../Components/Analyses/ticketsStatsTable";
import TicketPie from "../../Components/Analyses/ticketPie";

const MachineAnalysesPage = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const Pannes = useAppSelector(statsByPanne);
  const MachinePannesNumber = useAppSelector(numberPanneMachines);

  useEffect(() => {
    dispatch(findMachinesStats(null));
    dispatch(findMachineDowntime(null));
    dispatch(findNumberPanne(null));

    dispatch(
      findTicketsStats({
        start: startDate,
        end: endDate,
      })
    );
  }, [dispatch, startDate, endDate]);
  const [mergedData, setMergedData] = useState();

  return (
    <div className="admin-data-content container-fluid">
      <Navbar titre={"Dashboard"} />
      <div className="row date-filter-row">
        <div className="col-md-3 col-6 pb-2">
          <label htmlFor="start-date"></label>
          <input
            className="date-input form-control"
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) =>
              setStartDate(
                moment(e.currentTarget.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              )
            }
          />
        </div>
        <div className="col-md-3 col-6 pb-2">
          <label htmlFor="end-date"></label>
          <input
            className="date-input form-control"
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) =>
              setEndDate(
                moment(e.currentTarget.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              )
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">Machines</h6>
            <div className="widget-content">
              <TableMachines setMergedData={setMergedData} />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <h6 className="widget-title">Tickets</h6>
            <div className="widget-content">
              <TableTickets setMergedData={setMergedData} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <PieChartComponent data={MachinePannesNumber} />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 layout-spacing">
          <div className="widget widget-one">
            <div className="widget-content">
              <TicketPie data={Pannes} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineAnalysesPage;
