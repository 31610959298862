import React from "react";

// Event type
interface Event {
  id: number;
  title: string;
  month: number;  // 0 = January, 11 = December
  year: number;
  color?: string;
  data?: any; // Adjust as needed for your data
}
// Props for YearlyCalendar component
interface YearlyCalendarProps {
  events: Event[];
  onMonthClick?: (month: number) => void; // Optional callback when month is clicked
}

const YearlyCalendar: React.FC<YearlyCalendarProps> = ({
  events,
  onMonthClick,
}) => {
  const currentYear = new Date().getFullYear();

  // Group events by month
  const eventsByMonth = Array.from({ length: 12 }, (_, month) => ({
    month,
    events: events.filter((event) => event.year === currentYear && event.month === month),
  }));

  return (
    <div style={styles.calendarGrid}>
      {eventsByMonth.map(({ month, events }) => (
        <div
          key={month}
          style={styles.monthCell}
          onClick={() => onMonthClick?.(month)}
        >
          <h3 style={styles.monthTitle}>
            {new Date(currentYear, month).toLocaleString("default", { month: "long" })}
          </h3>
          {events.length > 0 ? (
            <ul style={styles.eventList}>
              {events.map((event, index) => (
                <li key={index} style={styles.eventItem}>
                  {event.title}
                </li>
              ))}
            </ul>
          ) : (
            <p style={styles.noEvents}
            //  onClick={()=> console.log(eventsByMonth)}
             >No Events</p>
          )}
        </div>
      ))}
    </div>
  );
};

// Styles
const styles: { [key: string]: React.CSSProperties } = {
  calendarGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "16px",
    padding: "16px",
  },
  monthCell: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  monthTitle: {
    fontSize: "1.2em",
    marginBottom: "8px",
    color: "#333",
  },
  eventList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  eventItem: {
    fontSize: "0.9em",
    color: "#333",
    backgroundColor: "#e0f7fa",
    borderRadius: "4px",
    padding: "4px 6px",
    margin: "4px 0",
  },
  noEvents: {
    fontSize: "0.9em",
    color: "#777",
  },
};

export default YearlyCalendar;
