import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListMachines,
  findMachinesApi,
} from "../../_App/Redux/Slices/machines/machineSlice";

import Loader from "../../_Layouts/Loader/Loader";
import { TicketType } from "../../_App/Types/Entites/TicketType";
import { CurrentTicket } from "../../_App/Redux/Slices/tickets/ticketSlice";
import Ticket from "../Tickets/Ticket";
interface LandingTicketsProps {
  filter: string;
}
const LandingTickets: React.FC<LandingTicketsProps> = ({ filter }) => {
  const dispatch = useAppDispatch();
  const tickets = useAppSelector(CurrentTicket);

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [ticket, setTicket] = useState<TicketType | undefined>(undefined);

  const ticketStyle: React.CSSProperties = {
    padding: "15px",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    background: "#fff",
    border: "1px dashed #ccc",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  const ticketCodeStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "5px 0",
    color: "#333",
  };

  const ticketTypeStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "#777",
    background: "#f0f0f0",
    borderRadius: "5px",
    padding: "5px 10px",
    textAlign: "center",
    width: "100%",
  };

  return (
    <div
      className="searchable-container m-0"
      style={{ maxWidth: "fit-content" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div
          className="layout-top-spacing"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Ticket
            ShowModal={showModal}
            setShowModal={setShowModal}
            setSelectedTicket={setTicket}
            selectedTicket={ticket}
            stop={true}
          />
          {tickets.length > 0
            ? tickets
                ?.filter((item) => item.machine_code.includes(filter))
                .map((item, index) => (
                  <div
                    key={index}
                    style={ticketStyle}
                    onClick={() => {
                      setTicket(item);
                      setShowModal(true);
                    }}
                  >
                    <p style={ticketCodeStyle}>{item.machine_code}</p>
                    <div style={{ fontSize: "14px", color: "#777" }}>
                      {item.type}
                    </div>
                  </div>
                ))
            : "Pas de tickets ouverts "}
        </div>
      )}
    </div>
  );
};

export default LandingTickets;
