import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import {
  downtimes,
  stats,
} from "../../_App/Redux/Slices/machines/machineSlice";
import PieChartComponent from "./MachinesPie";

// Define types for your data
interface MachineStat {
  code: string;
  planningCount: number;
  interventionCount: number;
  supportTicketCount: number;
}

interface InactiveTime {
  machine_code: string;
  total_inactif_time: string;
}

const TableMachines = ({ setMergedData }: any) => {
  const machinesStats: MachineStat[] = useAppSelector(stats);
  const inactiveTimes: InactiveTime[] = useAppSelector(downtimes);

  // Step 1: Merge the machinesStats and inactiveTimes
  const mergedData = useMemo(() => {
    return machinesStats.map((machine) => {
      const inactive = inactiveTimes.find(
        (inactive) => inactive.machine_code === machine.code
      );

      return {
        ...machine,
        total_inactif_time: inactive ? inactive.total_inactif_time : "N/A",
      };
    });
  }, [machinesStats, inactiveTimes]);
  useEffect(() => {
    if (mergedData && mergedData.length) setMergedData(mergedData);
  }, [mergedData]);

  return (
      <table id="style-3" className="table style-3 table-hover">
        <thead>
          <tr>
            <th onClick={() => console.log(mergedData)}>Machine</th>
            <th>ODPs</th>
            <th>Interventions</th>
            <th>Tickets</th>
            <th>{"Temps d'Arrêt (heures)"}</th>
          </tr>
        </thead>
        <tbody>
          {mergedData.length > 0 ? (
            mergedData.map((machine) => (
              <tr key={machine.code}>
                {" "}
                {/* Use machine code as key */}
                <td>{machine.code}</td>
                <td>{machine.planningCount}</td>
                <td>{machine.interventionCount}</td>
                <td>{machine.supportTicketCount}</td>
                <td>
                  {machine.total_inactif_time !== "N/A"
                    ? machine.total_inactif_time
                    : "_"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
  );
};

export default TableMachines;
