import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const TicketPie = ({ data }: { data: Record<string, any[]> }) => {
  // Prepare data for the pie chart
  const chartData = {
    labels: Object.keys(data), // Get the keys (panne types)
    datasets: [
      {
        label: "Number of Tickets",
        data: Object.values(data).map((tickets) => tickets.length), // Count the tickets (length of the arrays)
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF5733",
          "#4CAF50",
          "#FFB300",
          "#008CBA",
          "#F44336",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <h2 onClick={()=> console.log(data)}>Types des pannes</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default TicketPie;
