import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../_App/Redux/hooks";
import { ListMachinesByCode } from "../../../_App/Redux/Slices/machines/machineSlice";
import Loader from "../../../_Layouts/Loader/Loader";
import {
  Status,
  affectationChecklistByMachineGroup,
} from "../../../_App/Redux/Slices/checklists/checklistSlice";
import { ProgsProd } from "../../../_App/Redux/Slices/plannings/planningsSlice";

const ProgramsProd = () => {
  const ProgramsProd = useAppSelector(ProgsProd);
  const statusOdfChecklists = useAppSelector(Status);
  const affectations = useAppSelector(affectationChecklistByMachineGroup);
  const today = new Date().toISOString().split("T")[0];

  const [loading, setLoading] = useState(false);

  const hasStatus = (item: any) => {
    return (
      statusOdfChecklists &&
      statusOdfChecklists[item.odf_code] &&
      statusOdfChecklists[item.odf_code].length
    );
  };
  const isDone = (item: any) => {
    let totalChecklists = 0;
    let totalOK = 0;
    let totalKO = 0;
    if (
      statusOdfChecklists[item.odf_code] &&
      affectations[item.Machine?.groupe] &&
      statusOdfChecklists[item.odf_code].length ===
        affectations[item.Machine?.groupe].length
    ) {
      statusOdfChecklists[item.odf_code].map(
        (item) => (
          (totalChecklists += item.total_checklist),
          (totalKO += item.total_ko),
          (totalOK += item.total_ok)
        )
      );
      return {
        done: totalChecklists === totalKO + totalOK,
        totalChecklists: totalChecklists,
        totalKO: totalKO,
        totalOK: totalOK,
      };
    } else return { done: false };
  };
  const isDoneChecked = (item: any) => {
    if (isDone(item).done) {
      return isDone(item).totalKO === isDone(item).totalOK;
    } else {
      return false;
    }
  };
  const isDoneWithIssue = (item: any) => {
    const result = isDone(item);
    if (result && result.done) {
      return result.totalKO !== undefined && result.totalKO > 0;
    } else {
      return false;
    }
  };

  const progress = (item: any) => {
    const part =
      statusOdfChecklists[item.odf_code][0].total_ok +
      statusOdfChecklists[item.odf_code][0].total_ko;
    const total = statusOdfChecklists[item.odf_code][0].total_checklist;
    return Math.round((part / total) * 100);
  };
  const filteredItems = Object.keys(ProgramsProd)
    .filter((date) => date <= today)
    .flatMap((date) => ProgramsProd[date]);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-chart" style={{ display: "block" }}>
          {ProgramsProd &&
            filteredItems.map((item, index) =>
              !hasStatus(item) ? (
                <div
                  key={index}
                  className="row mb-2"
                  style={{
                    padding: "20px",
                    borderRadius: "10px",
                    background: "#fff",
                  }}
                >
                  <div style={{ display: "flex" }} key={item.id}>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Odf?.libelle} </p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Machine?.nom} </p>
                        <p style={{ color: "#fff" }}></p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : hasStatus(item) &&
                !isDoneChecked(item) &&
                !isDoneWithIssue(item) ? (
                <div
                  key={index}
                  className="row mb-2"
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex" }} key={item.id}>
                    <div
                      className="w-chart-section paid-visits-content col"
                      style={{
                        margin: "10px",
                        // background: "#e2a03f",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="row">
                        <p
                          className=""
                          style={{ color: "#e2a03f", width: "85%" }}
                        >
                          {" "}
                          {"Heure de début : " +
                            statusOdfChecklists[item.odf_code][0]
                              .heure_debut}{" "}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader spin mr-2 text-light"
                          style={{ width: "auto", color: "#e2a03f" }}
                        >
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="12"
                            y1="2"
                            x2="12"
                            y2="6"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="12"
                            y1="18"
                            x2="12"
                            y2="22"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="4.93"
                            y1="4.93"
                            x2="7.76"
                            y2="7.76"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="2"
                            y1="12"
                            x2="6"
                            y2="12"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="18"
                            y1="12"
                            x2="22"
                            y2="12"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            style={{ color: "#e2a03f" }}
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </div>

                      <div className="w-detail">
                        <p className="w-title" style={{ color: "#e2a03f" }}>
                          {" "}
                          {item.Odf?.libelle}{" "}
                        </p>
                        <p style={{ color: "#e2a03f" }}>
                          {statusOdfChecklists[item.odf_code][0].etat} par{" "}
                          {statusOdfChecklists[item.odf_code][0]?.User?.nom}
                        </p>
                        <div className="progress br-30 progress-md">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: progress(item) + "%" }}
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                        {/* <div className="row mb-3">
                        <div
                          className="w-progress-stats col-10"
                          style={{ display: "grid", alignItems: "center" }}
                        >
                          <div className="progress m-0">
                            <div className="progress br-30 progress-md">
                              <div className="progress br-30 progress-md">
                                <div
                                  className="progress-bar bg-warning"
                                  role="progressbar"
                                  style={{ width: "60%" }}
                                  aria-valuenow={75}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-chart-render-one col-2">
                          <div id="total-users" style={{ color: "#000" }}>
                            {" "}
                            {progress(item)}%{" "}
                          </div>
                        </div>
                      </div> */}
                        <div className="row">
                          <ul
                            className="table-controls"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <li className="text-danger">
                              {" "}
                              {
                                statusOdfChecklists[item.odf_code][0].total_ko
                              }{" "}
                            </li>
                            <li>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x-circle text-danger"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                              </span>
                            </li>
                            <li className="text-success">
                              {" "}
                              {
                                statusOdfChecklists[item.odf_code][0].total_ok
                              }{" "}
                            </li>
                            <li>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check-circle text-success"
                                >
                                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                              </span>
                            </li>
                            <li className="text-dark">
                              {" "}
                              {"De " +
                                statusOdfChecklists[item.odf_code][0]
                                  .total_checklist}{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Machine?.nom} </p>
                        <p style={{ color: "#fff" }}></p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : isDoneChecked(item) ? (
                <div
                  key={index}
                  className="row mb-2"
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex" }} key={item.id}>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        background: "#ddf5f0",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Odf?.libelle} </p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Machine?.nom} </p>
                        <p style={{ color: "#fff" }}></p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isDoneWithIssue(item) ? (
                <div
                  key={index}
                  className="row mb-2"
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ display: "flex" }} key={item.id}>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",

                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="row" style={{ justifyContent: "end" }}>
                        <div className="spinner-grow text-danger align-self-center mr-3"></div>
                      </div>
                      <div className="w-detail">
                        <p className="w-title mb-3"> {item.Odf?.libelle} </p>
                      </div>

                      <div className="row">
                        <ul
                          className="table-controls"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <li className="text-danger">
                            {" "}
                            {
                              statusOdfChecklists[item.odf_code][0].total_ko
                            }{" "}
                          </li>
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x-circle text-danger"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="15" y1="9" x2="9" y2="15"></line>
                                <line x1="9" y1="9" x2="15" y2="15"></line>
                              </svg>
                            </span>
                          </li>
                          <li className="text-success">
                            {" "}
                            {
                              statusOdfChecklists[item.odf_code][0].total_ok
                            }{" "}
                          </li>
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check-circle text-success"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                              </svg>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="w-chart-section total-visits-content col"
                      style={{
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "/curatifChecklists/" +
                          item.odf_code +
                          "/" +
                          item.machine_code)
                      }
                    >
                      <div className="w-detail">
                        <p className="w-title"> {item.Machine?.nom} </p>
                        <p style={{ color: "#fff" }}></p>
                      </div>
                      <div className="w-chart-render-one">
                        <div id="total-users"></div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : null
            )}
        </div>
      )}
    </div>
  );
};

export default ProgramsProd;
